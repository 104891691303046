
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

@Component({
    components: {
        VueSlickCarousel,
    },
})

export default class ImageView extends Vue {
    // @ts-ignore
    @Prop({required: true, default: false}) readonly public modalShow: boolean;
    // @ts-ignore
    @Prop({required: true, default: []}) readonly public imagesList: any;
    // @ts-ignore
    @Prop({required: true, default: ''}) readonly public title: any;
    // @ts-ignore
    @Prop({required: true, default: 0}) readonly public rating: any;

    public settings = {
        "centerMode": true,
        "centerPadding": "20px",
        "focusOnSelect": true,
        "infinite": true,
        "slidesToShow": 1,
        "speed": 500,
        "dots": true,
    };
    public closeModal() {
        this.$emit('close-modal', null);
    }
  
}
